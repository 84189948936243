export default function Arrows() {
  return (
    <svg
      width="177"
      height="148"
      viewBox="0 0 177 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.369 104.93C87.7086 101.079 84.9481 98.0505 81.0509 96.9174C78.0414 96.0349 74.7396 96.4561 72.0441 98.0706C71.3729 98.4717 69.5759 100.076 63.0156 106.153L54.8099 113.734L54.7774 80.7524L54.7557 47.7605L54.5068 46.9282C53.3159 42.7967 49.776 39.9488 45.3051 39.4875C40.4011 38.9861 35.8003 41.7338 34.1764 46.126C33.5594 47.7906 33.5919 45.9756 33.5919 81.2538L33.5919 113.744L25.3969 106.163C20.2981 101.45 16.9422 98.4216 16.5417 98.1809C12.2223 95.5135 6.67961 95.9948 3.06389 99.3542C1.03952 101.219 0.0110954 103.536 0.000269841 106.223C-0.0105557 107.918 0.303384 109.182 1.1153 110.686C2.03547 112.37 0.952915 111.348 22.2792 130.982L37.9762 145.432L39.0046 145.954C42.9451 147.919 47.5243 147.548 50.956 144.971C51.3782 144.65 59.454 137.23 68.8939 128.465C83.6924 114.737 86.1497 112.421 86.6802 111.678C87.7844 110.104 88.3365 108.52 88.4123 106.624C88.4448 106.023 88.4231 105.251 88.369 104.93Z"
        fill="#F7B449"
      />
      <path
        d="M88.486 42.2741C89.1464 46.1248 91.9069 49.1532 95.8041 50.2864C98.8136 51.1689 102.115 50.7477 104.811 49.1332C105.482 48.7321 107.279 47.1276 113.839 41.0507L122.045 33.4696L122.078 66.4514L122.099 99.4433L122.348 100.276C123.539 104.407 127.079 107.255 131.55 107.716C136.454 108.218 141.055 105.47 142.679 101.078C143.296 99.4132 143.263 101.228 143.263 65.95V33.4596L151.458 41.0407C156.557 45.7538 159.913 48.7822 160.313 49.0229C164.633 51.6903 170.175 51.209 173.791 47.8496C175.815 45.9844 176.844 43.668 176.855 40.9805C176.866 39.2858 176.552 38.0223 175.74 36.5181C174.82 34.8334 175.902 35.8562 154.576 16.2216L138.879 1.77133L137.85 1.24988C133.91 -0.715589 129.331 -0.344556 125.899 2.23262C125.477 2.55351 117.401 9.97417 107.961 18.7386C93.1627 32.4668 90.7053 34.7832 90.1748 35.5253C89.0706 37.0997 88.5185 38.6841 88.4427 40.5794C88.4103 41.1811 88.4319 41.9532 88.486 42.2741Z"
        fill="#F7B449"
      />
    </svg>
  );
}
