import { useEffect, useState } from 'react';
import api from '../Api/requests';
import Post from '../Types/Post';

export default function usePostsToRateFetch() {
  const [postsToRate, setPostsToRate] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.makePostsToRateRequest();
        setPostsToRate(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  return { postsToRate, loading, setLoading };
}
