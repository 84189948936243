import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import Headerbar from '../../General/Headerbar';
import Wave1 from '../../Icons/Wave1';
import LargeGears from '../../Icons/LargeGears';
import Wave4 from '../../Icons/Wave4';
import api from '../../Api/requests';
import ErrorPopup from '../../General/ErrorPopup';

export default function Settings() {
  const [newUsername, setNewUsername] = useState('');
  const [loadingUsernameResult, setLoadingUsernameResult] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  function handleUsernameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewUsername(e.target.value);
  }
  async function handleUsernameChangeKeyDown(
    e: React.KeyboardEvent<HTMLDivElement>,
  ) {
    if (e.key === 'Enter') {
      try {
        setLoadingUsernameResult(true);
        await api.makeChangeUsernameRequest(newUsername);
        setLoadingUsernameResult(false);
        navigate(`/profile/${newUsername}`);
      } catch (err: any) {
        setLoadingUsernameResult(false);
        setError(err.response.data.message);
      }
    }
  }

  async function submitChanges() {
    try {
      setLoadingUsernameResult(true);
      await api.makeChangeUsernameRequest(newUsername);
      setLoadingUsernameResult(false);
      navigate(`/profile/${newUsername}`);
    } catch (err: any) {
      setLoadingUsernameResult(false);
      setError(err.response.data.message);
    }
  }
  return (
    <div className="h-screen w-full bg-purple-200 border-t relative overflow-hidden">
      <Headerbar />
      {error !== '' && (
        <ErrorPopup error={error} clearError={() => setError('')} />
      )}
      <p className="text-white sm:text-6xl text-3xl font-bold mt-40 ml-10">Settings</p>
      <div className="w-full relative">
        <Wave1 />
        <div className="absolute -bottom-10 right-10 sm:w-96 w-32">
          <LargeGears />
        </div>
      </div>
      <div className="bg-purple-50 w-full py-10 flex flex-col justify-center">
        <div className="sm:w-2/3 w-5/6 mx-auto">
          <div className="bg-purple-100 rounded">
            <div className="w-full flex flex-row justify-between items-center">
              <p className="sm:text-2xl text-lg text-purple-200 font-bold sm:px-10 px-2 py-5">
                Edit Username:
              </p>
              {loadingUsernameResult ? (
                <div className="h-10 w-1/3">
                  <TailSpin color="#fff" width={40} height={40} />
                </div>
              ) : (
                <input
                  type="text"
                  value={newUsername}
                  onChange={handleUsernameChange}
                  className="text-white sm:text-2xl text-lg sm:w-1/3 w-2/3 px-5 bg-purple-200 h-10 sm:mr-20 mr-4 rounded"
                  onKeyDown={handleUsernameChangeKeyDown}
                />
              )}
            </div>
          </div>
          <button className="bg-purple-100 w-40 rounded text-purple-200 mt-5 font-bold sm:text-2xl text-lg py-3" type="button" onClick={submitChanges}>
            Submit
          </button>
        </div>
      </div>
      <div className="bg-purple-200 w-full">
        <Wave4 />
      </div>
    </div>
  );
}
