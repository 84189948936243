/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import Post from '../../Types/Post';

export default function RatePost({
  post,
  onSelectHook,
}: {
  post: Post;
  onSelectHook: (ind: number) => void;
}) {
  const colors = ['#fc2003', '#fc8803', '#ffe600', '#77ff00', '#00e617'];
  const [lockedIn, setLockedIn] = useState(false);
  const [currentColor, setCurrentColor] = useState(-1);
  const [lockedInValue, setLockedInValue] = useState(-1);
  function selectColor(colorIndex: number) {
    setLockedIn(true);
    setLockedInValue(colorIndex);
    onSelectHook(colorIndex + 1);
  }
  return (
    <>
      <div className="bg-purple-100 rounded-lg p-4 w-full h-3/4 flex flex-col">
        <h1 className="text-white text-xl font-semibold">{post.prompt}</h1>
        <div className="bg-white w-full text-xl focus:outline-none py-2 px-4 rounded mt-4 whitespace-normal">
          {post.title}
        </div>
        <h1 className="text-white text-xl font-semibold py-2">
          By:
          {' '}
          {post?.author}
          {' - '}
          <span className="text-amber-300 text-xl font-bold">
            {post?.points}
            {' '}
            pts.
          </span>
        </h1>
        <div className="bg-white w-full sm:h-80 h-150 text-lg focus:outline-none p-8 rounded mt-2 break-words overflow-y-auto">
          {post?.body}
        </div>
      </div>
      <div className="w-full flex flex-row justify-center my-10">
        {colors.map((color, index) => {
          if (
            (index <= currentColor && !lockedIn)
            || (index <= lockedInValue && lockedIn)
          ) {
            return (
              <button
                type="button"
                style={{
                  backgroundColor: lockedIn
                    ? colors[lockedInValue]
                    : colors[currentColor],
                }}
                className="w-10 h-10 mx-2 rounded-full"
                key={color}
                onMouseEnter={() => setCurrentColor(index)}
                onMouseLeave={() => setCurrentColor(-1)}
                onClick={() => selectColor(index)}
              />
            );
          }
          return (
            <button
              type="button"
              className="border border-slate-400 w-10 h-10 mx-2 rounded-full"
              onMouseEnter={() => setCurrentColor(index)}
              onMouseLeave={() => setCurrentColor(-1)}
              key={color}
              onClick={() => selectColor(index)}
            />
          );
        })}
      </div>
    </>
  );
}
