import React, { useEffect } from 'react';

export default function useLocalStorageReadWrite(
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  tag: string,
) {
  useEffect(() => {
    if (localStorage.getItem(tag) !== null) {
      setValue(JSON.parse(localStorage.getItem(tag) as string));
    }
  }, [tag, setValue]);
  useEffect(() => {
    localStorage.setItem(tag, JSON.stringify(value));
  }, [tag, value]);
  function clearValue() {
    localStorage.removeItem(tag);
    setValue('');
  }
  return { clearValue };
}
