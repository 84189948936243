import Headerbar from '../../General/Headerbar';
import Austronaut from '../../Icons/Austronaut';
import Pencil from '../../Icons/Pencil';
import Planet from '../../Icons/Planet';
import Wave1 from '../../Icons/Wave1';
import Wave2 from '../../Icons/Wave2';
import Arrows from '../../Icons/Arrows';
import Prize from '../../Icons/Prize';
import Wave3 from '../../Icons/Wave3';
import Wave4 from '../../Icons/Wave4';
import Knights from '../../Icons/Knights';
import Chess from '../../Icons/Chess';
import Footer from '../../General/Footer';

export default function Homepage() {
  return (
    <div>
      <Headerbar />
      <div className="relative xl:h-screen flex flex-col">
        <div className="flex flex-col pt-40 xl:pt-0 xl:flex-row items-center h-full w-full bg-purple-200 overflow-hidden">
          <h1 className="animate__animated animate__fadeInDown sm:mx-24 mx-8 font-sans font-bold lg:text-7xl text-5xl text-white flex-shrink-0 pb-10 sm:pb-0">
            Develop, Publish, &
            {' '}
            <br />
            {' '}
            Explore
            {' '}
            <br />
            {' '}
            creative writing.
          </h1>
          <div className="relative ml-auto w-full flex flex-col justify-between pt-10 items-center h-full">
            <div className="animate__animated animate__fadeIn hidden 3xl:block ml-auto mr-10 mt-40">
              <Planet />
            </div>
            <div className="animate__animated animate__fadeIn xl:absolute bottom-0 left-5 sm:w-140 w-96">
              <Austronaut />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full overflow-hidden">
          <Wave1 />
        </div>
      </div>
      <div className="w-full bg-purple-200 overflow-hidden">
        <div className="bg-purple-50">
          <p className="xl:px-80 px-10 py-20 font-extrabold text-purple-200 text-4xl opacity-60">
            Put your creative works out there! As a student, writer, poet, or
            astronaut, your imagination and originality can be shared with a
            community of like-minded people.
          </p>
        </div>
        <div className="w-full">
          <Wave2 />
        </div>
        <div className="flex flex-col lg:flex-row items-center py-16 px-20 justify-center w-full gap-14">
          <div className="flex flex-col gap-8 justify-center items-center">
            <Pencil />
            <div className="drop-shadow bg-purple-50 rounded-3xl h-72 w-80 flex flex-row items-center justify-center text-center">
              <p className="px-4 opacity-70 font-sans font-extrabold text-purple-200 text-3xl">
                Write a story on a different prompt daily.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-8 justify-center items-center">
            <Arrows />
            <div className="drop-shadow bg-purple-50 rounded-3xl h-72 w-80 flex flex-row items-center justify-center text-center">
              <p className="px-4 opacity-70 font-sans font-extrabold text-purple-200 text-3xl">
                Rate and explore other stories on the same prompt.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-8 justify-center items-center">
            <Prize />
            <div className="drop-shadow bg-purple-50 rounded-3xl h-72 w-80 flex flex-row items-center justify-center text-center">
              <p className="px-4 opacity-70 font-sans font-extrabold text-purple-200 text-3xl">
                See global rankings and daily winners!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-purple-50 overflow-hidden pb-10">
        <div className="w-full">
          <Wave3 />
        </div>
        <div className="w-full lg:px-28 px-8 flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-1/2 w-full lg:px-10 flex flex-col justify-start">
            <h1 className="text-amber-100 text-6xl font-bold">
              How TaleTest works
            </h1>
            <p className="mt-16 font-bold text-purple-200 text-3xl">
              TaleTest is an online platform designed for people to improve and
              enjoy creating writing. Through daily prompts and a community
              engagement system, competition kindles and ideas turn to
              masterpieces.
            </p>
          </div>
          <div className="lg:w-1/2 w-full ml-20">
            <Knights />
          </div>
        </div>
        <div className="w-full lg:px-28 px-8 flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-2/5 w-full ml-10 order-3 lg:order-1">
            <Chess />
          </div>
          <div className="order-2 lg:w-1/2 w-full lg:px-10 flex flex-col justify-start">
            <h1 className="text-amber-100 text-6xl font-bold">
              Point and ranking system
              {' '}
            </h1>
            <p className="mt-16 font-bold text-purple-200 text-3xl">
              TaleTest&apos;s ranking system gives the power to the user. For every
              essay, winners are selected by having the highest points. Points
              are awarded by other users. Each writer scores out of 6 and the
              total points are added to their overall profile count.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full bg-purple-200">
        <Wave4 />
      </div>
      <Footer />
    </div>
  );
}
