export default function Wave2() {
  return (
    <svg
      viewBox="0 0 1770 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_76_17)">
        <path
          d="M-11 12.9L48.3667 17.2134C107.733 21.3656 226.467 30.2344 345.2 47.2866C463.933 64.5 582.667 90.3 701.4 92.4366C820.133 94.7344 938.867 72.9656 1057.6 53.7366C1176.33 34.2656 1295.07 17.3344 1413.8 17.2134C1532.53 17.3344 1651.27 34.2656 1710.63 43.0134L1770 51.6V0H1710.63C1651.27 0 1532.53 0 1413.8 0C1295.07 0 1176.33 0 1057.6 0C938.867 0 820.133 0 701.4 0C582.667 0 463.933 0 345.2 0C226.467 0 107.733 0 48.3667 0H-11V12.9Z"
          fill="#DDE9F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_76_17">
          <rect
            width="1781"
            height="129"
            fill="white"
            transform="matrix(1 0 0 -1 -11 129)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
