/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/order */
/* eslint-disable import/first */
import axios from 'axios';
import Cookies from 'js-cookie';
import { apiUrl } from './settings';
import Post from '../Types/Post';
import Prompt from '../Types/Prompt';
import LeaderboardResult from '../Types/LeaderboardResult';
import UserData from '../Types/UserData';

const makeLoginRequest = async (
  email: string,
  token: string,
  profileIcon: string,
) => axios.post(
  `${apiUrl}/login`,
  { email, token, profileIcon },
  { withCredentials: true },
);

const makeChangeUsernameRequest = async (username: string) => axios.put(`${apiUrl}/update-user`, { username }, { withCredentials: true });

const makePostSubmitRequest = async (
  title: string,
  body: string,
  isPrivate: boolean,
) => axios.post<{
  data: string;
  message: string;
}>(
  `${apiUrl}/submit-post`,
  { title, body, isPrivate },
  { withCredentials: true },
);

const makeUserDataRequest = async (username: string) => axios.get<{
    data: UserData;
    message: string;
  }>(`${apiUrl}/get-user`, { withCredentials: true, headers: { username } });

const makePublicUserPostsRequest = async (username: string) => axios.get<{
    data: Post[];
    message: string;
}>(`${apiUrl}/get-public-posts?username=${username}`, { withCredentials: true });

const makePrivateUserPostsRequest = async () => axios.get<{
    data: Post[];
    message: string;
}>(`${apiUrl}/get-private-posts`, { withCredentials: true });

const makePublicUserPostRequest = async (id: string) => axios.get<{
    data: Post;
    message: string;
}>(`${apiUrl}/get-public-post?id=${id}`, { withCredentials: true });

const makePrivateUserPostRequest = async (id: string) => axios.get<{
  data: Post;
  message: string;
}>(`${apiUrl}/get-private-post?id=${id}`, { withCredentials: true });

const makePromptRequest = async () => axios.get<{
  data: Prompt;
  message: string;
}>(`${apiUrl}/get-new-prompt`, { withCredentials: true });

const makePaginatedPostsRequest = async (page: number, pageSize: number) => axios.get<{
    data: {
      totalPages: number;
      posts: Post[];
    },
    message: string;
  }>(`${apiUrl}/get-public-posts?page=${page}&pageSize=${pageSize}`, { withCredentials: true });

const makeFullLeaderboardRequest = async () => axios.get<{
  data: LeaderboardResult[];
  message: string;
}>(`${apiUrl}/get-full-leaderboard`, { withCredentials: true });

const makeCheckPublicPostRequest = async () => axios.get<{
  message: string;
}>(`${apiUrl}/check-public-post`, { withCredentials: true });

const makePostsToRateRequest = async () => axios.get<{
  data: Post[];
  message: string;
}>(`${apiUrl}/get-posts-to-rate`, { withCredentials: true });

const makeRatePostsRequest = async (ratings: number[]) => axios.put<{
  message: string;
}>(`${apiUrl}/submit-post-ratings`, { ratings }, { withCredentials: true });

// @ts-ignore
import Worker from 'worker-loader!../Worker.js';
// eslint-disable-next-line no-unused-vars
const workerInstance = Worker();

axios.interceptors.request.use((config) => {
  const accessToken = Cookies.get('accessToken');
  if (config?.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

const api = {
  makeLoginRequest,
  makeChangeUsernameRequest,
  makePostSubmitRequest,
  makeUserDataRequest,
  makePublicUserPostsRequest,
  makePrivateUserPostsRequest,
  makePublicUserPostRequest,
  makePrivateUserPostRequest,
  makePromptRequest,
  makePaginatedPostsRequest,
  makeFullLeaderboardRequest,
  makePostsToRateRequest,
  makeCheckPublicPostRequest,
  makeRatePostsRequest,
};

export default api;
