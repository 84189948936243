export default function ErrorPopup({
  clearError,
  error,
}: {
  clearError: () => void;
  error: string;
}) {
  return (
    <div className="bg-red-200 border-t absolute top-32 right-5 w-1/5 z-10 rounded-lg pb-8">
      <button
        className="text-white w-full text-right pr-8 text-2xl"
        onClick={clearError}
        type="button"
      >
        X
      </button>
      <p className="text-white text-xl w-full text-center">{error}</p>
    </div>
  );
}
