import { Link } from 'react-router-dom';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { TailSpin } from 'react-loader-spinner';
import { useState } from 'react';
import Cookies from 'js-cookie';
import Logo from '../../Icons/Logo';
import api from '../../Api/requests';

export default function Signin() {
  const [loading, setLoading] = useState(false);
  async function handleGoogleResponse(res: GoogleLoginResponse | GoogleLoginResponseOffline) {
    // get email from google response
    if ((res as GoogleLoginResponse).profileObj) {
      setLoading(true);
      const { email } = (res as GoogleLoginResponse).profileObj;
      const token = (res as GoogleLoginResponse).tokenId;
      const profileIcon = (res as GoogleLoginResponse).profileObj.imageUrl;
      await api.makeLoginRequest(email, token, profileIcon);
      // send to profile page
      window.location.href = `/profile/${Cookies.get('username')}`;
    }
  }
  return (
    <div className="bg-purple-200 w-full h-screen flex flex-col items-center justify-center gap-16">
      <Link to="/" className="sm:w-140 w-80"><Logo /></Link>
      <div className="sm:w-150 sm:h-80 w-80 h-96 bg-purple-50 rounded-3xl shadow flex flex-col items-center">
        <p className="text-purple-200 font-extrabold sm:text-4xl text-2xl text-center mt-4 px-8">Please sign in to get started</p>
        {
          loading
            ? (
              <div className="mt-20">
                <TailSpin arialLabel="loading-indicator" color="white" />
              </div>
            )
            : (
              <GoogleLogin
                clientId="400565140821-de00cdrnhsrimnlsc5lrjvut8ilun0qr.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={handleGoogleResponse}
                onFailure={() => {}}
                cookiePolicy="single_host_origin"
                className="w-56 flex items-center justify-center sm:mt-20 mt-24"
              />
            )
        }
      </div>
    </div>
  );
}
