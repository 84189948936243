import { useState, useEffect } from 'react';
import api from '../Api/requests';
import LeaderboardResult from '../Types/LeaderboardResult';

export default function useLeaderboardFetch() {
  const [leaderboard, setLeaderboard] = useState<LeaderboardResult[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.makeFullLeaderboardRequest();
        setLeaderboard(res.data.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  return { leaderboard, loading };
}
