/* eslint-disable no-underscore-dangle */
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import Cookies from 'js-cookie';
import Switch from 'react-switch';
import Headerbar from '../../General/Headerbar';
import Wave1 from '../../Icons/Wave1';
import SmallGear from '../../Icons/SmallGear';
import ProfilePost from './ProfilePost';
import api from '../../Api/requests';
import UserData from '../../Types/UserData';
import Top1 from '../../Icons/Top1';
import Top2 from '../../Icons/Top2';
import Top3 from '../../Icons/Top3';
import Top10 from '../../Icons/Top10';

const todaysDay = new Date(new Date().setUTCHours(0, 0, 0, 0)).getUTCDay();

export default function ProfilePage() {
  const { username } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [userData, setUserData] = useState<
    UserData | undefined
  >();
  console.log(userData);
  // eslint-disable-next-line no-unused-vars
  const [privateUserPosts, setPrivateUserPosts] = useState<
    {
      _id: string;
      title: string;
      body: string;
      createdAt: string;
      points: number;
      isPrivate: boolean;
    }[]
  >();
  const [showingPublic, setShowingPublic] = useState(true);
  const [userPosts, setUserPosts] = useState<
      {
        _id: string;
        title: string;
        body: string;
        createdAt: string;
        points: number;
        isPrivate: boolean;
      }[]
    >();

  useEffect(() => {
    async function fetchUserData() {
      if (!username) return;
      const res = await api.makeUserDataRequest(username);
      setUserData(res.data.data);
    }
    async function fetchPostData() {
      if (!username) return;
      const postRes = await api.makePublicUserPostsRequest(username);
      setUserPosts(postRes.data.data);
    }
    fetchUserData();
    fetchPostData();
  }, [username]);

  useEffect(() => {
    if (showingPublic) return;
    async function fetchPrivatePostData() {
      if (!username) return;
      if (privateUserPosts) return;
      const postRes = await api.makePrivateUserPostsRequest();
      setPrivateUserPosts(postRes.data.data);
    }
    fetchPrivatePostData();
  }, [username, showingPublic, privateUserPosts]);

  function handlePrivateOptionChange(isPublic: boolean) {
    setShowingPublic(isPublic);
  }
  return (
    <div className="h-screen w-full bg-purple-200 border-t flex flex-col">
      <Headerbar />
      <div className="mt-40 w-2/3 mx-auto flex flex-row justify-between">
        <div>
          {userData?.profileIcon !== undefined ? (
            <>
              <img
                src={userData?.profileIcon}
                alt="profile"
                className="h-28 w-28 border-8 border-purple-100 rounded-full"
              />
              <div className="text-white lg:text-6xl text-2xl font-bold mt-4 flex flex-row lg:gap-4 gap-2 items-end justify-center">
                <p>{userData?.username}</p>
                {
                  userData?.username === Cookies.get('username') && (
                    <Link to="/settings" className="lg:w-10 lg:h-10 w-7 h-7 mb-0.5 hover:rotate-45 transition duration-150 ease-out">
                      <SmallGear />
                    </Link>
                  )
                }
              </div>
              <p className="text-amber-100 text-2xl md:text-4xl font-bold mt-1 sm:pl-0 pl-2">
                {userData?.points}
              </p>
            </>
          ) : (
            <TailSpin arialLabel="loading-indicator" color="white" />
          )}
        </div>
      </div>
      <div className="w-full">
        <Wave1 />
      </div>
      <div className="bg-purple-50 pb-10">
        <div className="lg:w-2/3 w-4/5 mx-auto flex lg:flex-row flex-col gap-10 mt-4">
          <div className="h-150 self-start lg:w-1/2 w-full bg-purple-100 flex flex-col gap-6 items-center p-6 rounded-xl overflow-y-auto">
            <h1 className="text-purple-200 text-5xl font-bold">
              Posts
            </h1>
            {
              userData?.username === Cookies.get('username')
              && (
              <div className="flex flex-row gap-4 mt-4 items-center">
                <Switch onChange={handlePrivateOptionChange} checked={showingPublic} onColor="#4ade80" />
                <p className="text-white text-2xl font-semibold">
                  {showingPublic ? 'Showing public posts' : 'Showing private posts'}
                </p>
              </div>
              )
            }
            {
              (showingPublic && !userPosts) || (!showingPublic && !privateUserPosts)
                ? <TailSpin arialLabel="loading-indicator" color="white" />
                : (
                  // @ts-ignore
                  (showingPublic ? userPosts : privateUserPosts).map((post) => (
                    <ProfilePost
                      key={post._id}
                      title={post.title}
                      date={new Date(post.createdAt)}
                      points={post.points}
                      id={post._id}
                      isPrivate={post.isPrivate}
                    />
                  )))
            }
          </div>
          <div className="lg:w-1/2 w-full flex flex-col gap-5 items-center">
            <div className="w-full rounded-xl bg-purple-100">
              <h1 className="text-purple-200 text-4xl font-bold text-center p-4">
                Trophies
              </h1>
              <div className="flex flex-row flex-nowrap gap-2 overflow-x-scroll h-40 p-4">
                {
                  userData?.trophies.map((trophy) => {
                    switch (trophy) {
                      case 'top-1':
                        return <div><Top1 /></div>;
                      case 'top-2':
                        return <div><Top2 /></div>;
                      case 'top-3':
                        return <div><Top3 /></div>;
                      case 'top-10':
                        return <div><Top10 /></div>;
                      default:
                        return null;
                    }
                  })
                }
              </div>
            </div>
            <div className="w-full rounded-xl bg-purple-100">
              <h1 className="text-purple-200 text-4xl font-bold text-center p-2">
                Streak
              </h1>
              <p className="text-white text-center font-semibold pb-2 lg:px-0 px-3">
                (Streaks are tracked based on UTC timezone)
              </p>
              <div className="bg-purple-200 p-5 rounded-lg mx-4 mb-4">
                <h1 className="text-white text-center text-3xl font-semibold">
                  {userData?.streakDaysCount}
                </h1>
                <div className="flex flex-row gap-2 mt-4 mt-3 w-full justify-center">
                  {userData?.streakDays.slice(0, todaysDay + 1).map((done, index) => {
                    const day = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][
                      index
                    ];
                    return (
                      <div
                        key={day}
                        className="flex flex-col items-center justify-center"
                      >
                        <p className="text-white text-semibold lg:text-lg text-sm">
                          {day}
                        </p>
                        <div
                          className={`rounded-full ${
                            done ? 'bg-green-400' : 'bg-red-400'
                          } lg:w-10 lg:h-10 w-5 h-5`}
                        />
                      </div>
                    );
                  })}
                  {Array.from(
                    { length: 7 - (todaysDay + 1) },
                    (_, i) => i + todaysDay + 1,
                  ).map((index) => {
                    const day = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][
                      index
                    ];
                    return (
                      <div
                        key={day}
                        className="flex flex-col items-center justify-center"
                      >
                        <p className="text-white text-semibold lg:text-lg text-sm">
                          {day}
                        </p>
                        <div className="rounded-full bg-neutral-400 lg:w-10 lg:h-10 w-5 h-5" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-full rounded-xl bg-purple-100">
              <h1 className="text-purple-200 text-4xl font-bold text-center p-4">
                Started On
              </h1>
              <div className="bg-purple-200 p-5 rounded-lg mx-4 mb-4">
                <h1 className="text-white text-center text-3xl font-semibold">
                  {
                    userData ? new Date(userData.startedOn).toLocaleDateString() : 'Loading...'
                  }
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
