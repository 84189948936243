export default function Wave1({ color }: { color?: string }) {
  return (
    <svg
      viewBox="0 0 1770 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-11 98L48.3667 107.8C107.733 117.6 226.467 137.2 345.2 130.646C463.933 124.337 582.667 91.2625 701.4 98C820.133 104.737 938.867 150.062 1057.6 166.6C1176.33 183.137 1295.07 169.662 1413.8 160.046C1532.53 150.062 1651.27 143.937 1710.63 140.446L1770 137.2V196H1710.63C1651.27 196 1532.53 196 1413.8 196C1295.07 196 1176.33 196 1057.6 196C938.867 196 820.133 196 701.4 196C582.667 196 463.933 196 345.2 196C226.467 196 107.733 196 48.3667 196H-11V98Z"
        fill={color}
      />
    </svg>
  );
}
Wave1.defaultProps = {
  color: '#DDE9F8',
};
