import { useState, useEffect } from 'react';
import api from '../Api/requests';
import Post from '../Types/Post';

export default function usePaginatedPostsFetch(page: number) {
  const [posts, setPosts] = useState<Post[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const fetchPosts = async () => {
      try {
        const response = await api.makePaginatedPostsRequest(page, 10);
        setIsLoading(false);
        setPosts(response.data.data.posts);
        setTotalPages(response.data.data.totalPages);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchPosts();
  }, [page]);
  return {
    posts,
    totalPages,
    isLoading,
  };
}
