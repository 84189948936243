/* eslint-disable no-unused-vars */
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { SlideDown } from 'react-slidedown';
import Post from '../../Types/Post';
import ArrowHeadDown from '../../Icons/ArrowHeadDown';

export default function ExplorerPost({
  body, title, createdAt, points, author, _id,
}: Post) {
  const [showingBody, setShowingBody] = useState(false);
  function toggleBody() {
    setShowingBody(!showingBody);
  }
  return (
    <div className="bg-purple-50 w-screen rounded-md pt-1 my-4 sm:w-1/2 w-4/5">
      <div className="w-full flex flex-row justify-between">
        <h1 className="sm:text-lg text-sm p-4 font-bold text-purple-200">
          {title}
          {' '}
          <span className="text-purple-100">
            by
          </span>
          {' '}
          <Link to={`/profile/${author}`} className="underline">
            {author}
          </Link>
          {', '}
          <Link to={`/post/${_id}?public=true`} className="underline">
            visit
          </Link>
        </h1>
        <h1 className="shrink-0 text-purple-200 sm:text-lg text-sm font-bold p-4 flex flex-row justify-center h-full sm:h-auto">
          <button type="button" className="sm:mx-10 mx-2 font-bold text-xl" onClick={toggleBody}>
            <ArrowHeadDown />
          </button>
          {points}
          {' '}
          pts.
        </h1>
      </div>

      <SlideDown className="my-dropdown-slidedown">
        {showingBody && (
        <p className="pb-4 px-4 break-words">{body}</p>)}
      </SlideDown>

    </div>
  );
}
