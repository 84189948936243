/* eslint-disable no-underscore-dangle */
import { TailSpin } from 'react-loader-spinner';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Headerbar from '../../General/Headerbar';
import usePostsToRateFetch from '../../Hooks/usePostsToRateFetch';
import RatePost from './RatePost';
import api from '../../Api/requests';
import LargeArrowHead from '../../Icons/LargeArrowHead';
import ErrorPopup from '../../General/ErrorPopup';

export default function Rate() {
  const { postsToRate, loading, setLoading } = usePostsToRateFetch();
  const [currentSelectedPostIndex, setCurrentSelectedPostIndex] = useState(0);
  const [currentRatings, setCurrentRatings] = useState<number[]>([]);
  const [readyToProceed, setReadyToProceed] = useState(false);
  const [noPosts, setNoPosts] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    if (!loading) {
      setCurrentRatings(postsToRate.map(() => -1));
      if (postsToRate.length === 0) {
        setNoPosts(true);
      }
    }
  }, [postsToRate, loading]);

  useEffect(() => {
    if (
      !loading
      && currentRatings.filter((e) => e === -1).length === 0
      && currentRatings.length !== 0
    ) {
      setReadyToProceed(true);
      // scroll down to the bottom of mainRatePage
      const mainRatePage = document.getElementById('mainRatePage');
      if (mainRatePage) {
        setTimeout(() => {
          // smoothly scroll down
          mainRatePage.scrollTo({
            top: mainRatePage.scrollHeight,
            behavior: 'smooth',
          });
        }, 100);
      }
    }
  }, [currentRatings, loading]);

  function goLeft() {
    if (currentSelectedPostIndex > 0) setCurrentSelectedPostIndex((i) => i - 1);
  }
  function goRight() {
    if (currentSelectedPostIndex < postsToRate.length - 1) {
      setCurrentSelectedPostIndex((i) => i + 1);
    }
  }
  function ratePostFnGenerator(index: number, points: number) {
    setCurrentRatings((arr) => arr.map((e, i) => {
      if (i === index) return points;
      return e;
    }));
  }
  async function submitRatingsAndPost() {
    try {
      setLoading(true);
      await api.makeRatePostsRequest(currentRatings);
      let text = localStorage.getItem('currentText');
      let title = localStorage.getItem('currentTitle');
      if (text && title) {
        text = JSON.parse(text) as string;
        title = JSON.parse(title) as string;
        const res = await api.makePostSubmitRequest(title, text, false);
        localStorage.removeItem('currentText');
        localStorage.removeItem('currentTitle');
        navigate(`/post/${res.data.data}?public=true`);
      }
    } catch (e) {
      setError('There was an error submitting the post. Please go to the Write page and resubmit');
    }
  }
  return (
    <div className="bg-purple-200 w-screen h-screen overflow-x-hidden flex flex-col" id="mainRatePage">
      <Headerbar />
      {
        error && (
          <ErrorPopup error={error} clearError={() => setError('')} />
        )
      }
      <h1 className="mx-auto p-4 text-white sm:text-2xl text-xl font-bold mt-28 sm:text-left text-center">
        Before submitting a post, please rate up to 5 other posts
      </h1>
      <p className="mx-auto text-white pb-10 px-4 sm:text-left text-center">
        Be honest in your rating. Too many poor ratings will decrease your
        post&apos;s overall rating.
      </p>
      {loading ? (
        <div className="mx-auto"><TailSpin arialLabel="loading-indicator" color="white" /></div>
      ) : (
        postsToRate.map((post, index) => (
          <>
            <div
              className={`sm:w-2/3 w-4/5 mx-auto shrink w-full flex flex-row justify-between items-center gap-2 ${
                currentSelectedPostIndex === index ? '' : 'hidden'
              }`}
              key={post._id}
            >
              <button
                onClick={goLeft}
                type="button"
                className={`text-white h-40 w-40 rotate-90 mb-28 sm:block hidden ${
                  currentSelectedPostIndex === 0 ? 'invisible' : ''
                }`}
              >
                <LargeArrowHead color="#FFFFFF" />
              </button>
              <div className="w-full">
                <RatePost
                  post={post}
                  onSelectHook={(amount: number) => ratePostFnGenerator(index, amount)}
                />
              </div>
              <button
                onClick={goRight}
                type="button"
                className={`text-white h-40 w-40 rotate-[270deg] mb-28 sm:block hidden ${
                  currentSelectedPostIndex === postsToRate.length - 1
                    ? 'invisible'
                    : ''
                }`}
              >
                <LargeArrowHead color="#FFFFFF" />
              </button>
            </div>
            <div className={`sm:hidden block mb-14 mx-auto ${
              currentSelectedPostIndex === index ? '' : 'hidden'
            }`}
            >
              <button
                onClick={goLeft}
                type="button"
                className={`text-white h-40 w-40 rotate-90 ${
                  currentSelectedPostIndex === 0 ? 'invisible' : ''
                }`}
              >
                <LargeArrowHead color="#FFFFFF" />
              </button>
              <button
                onClick={goRight}
                type="button"
                className={`text-white h-40 w-40 rotate-[270deg] ${
                  currentSelectedPostIndex === postsToRate.length - 1
                    ? 'invisible'
                    : ''
                }`}
              >
                <LargeArrowHead color="#FFFFFF" />
              </button>
            </div>
          </>
        ))
      )}
      {
            (noPosts && !loading) && (
              <h1 className="p-4 text-white sm:text-2xl text-xl font-bold mx-auto h-full">
                There are no posts to rate, feel free to submit.
              </h1>
            )
          }
      {((readyToProceed || noPosts) && !loading) && (
      <button type="button" className="w-64 mx-auto bg-purple-100 text-white p-3 rounded-lg mb-5 font-semibold shrink-0" onClick={submitRatingsAndPost}>
        Submit Ratings and Post
      </button>
      )}
    </div>
  );
}
