import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Headerbar from '../../General/Headerbar';
import useLeaderboardFetch from '../../Hooks/useLeaderboardFetch';

export default function Leaderboard() {
  const { leaderboard, loading } = useLeaderboardFetch();
  const [timeTillUpdate, setTimeTillUpdate] = useState(
    new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime()
      + 24 * 60 * 60 * 1000
      - new Date().getTime(),
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeTillUpdate(
        new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime()
          + 24 * 60 * 60 * 1000
          - new Date().getTime(),
      );
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const hours = Math.floor(timeTillUpdate / (60 * 60 * 1000));
  const minutes = Math.floor(
    (timeTillUpdate - hours * 60 * 60 * 1000) / (60 * 1000),
  );
  const seconds = Math.floor(
    (timeTillUpdate - hours * 60 * 60 * 1000 - minutes * 60 * 1000) / 1000,
  );
  return (
    <div className="bg-purple-200 w-screen sm:h-screen min-h-screen sm:pb-0 pb-4 pt-1 flex flex-col">
      <Headerbar />
      <div className="h-28 flex-shrink-0" />
      <p className="text-white text-center pt-4 font-bold sm:text-xl px-10 sm:pb-0 pb-5">
        Time till new prompt:
        {' '}
        {hours}
        {' '}
        hours,
        {' '}
        {minutes}
        {' '}
        minutes,
        {' '}
        {seconds}
        {' '}
        seconds
      </p>
      <div className="flex flex-col items-center justify-center h-full">
        <div className="h-100 w-2/3 bg-purple-100 rounded overflow-hidden overflow-y-auto">
          {loading ? (
            <div className="w-full h-full flex flex-row items-center justify-center">
              <TailSpin color="#fff" width={100} height={100} />
            </div>
          ) : (
            leaderboard.map((user, i) => (
              <div
                key={user.username}
                className={`flex flex-row justify-between p-4
              ${i === 0 ? 'bg-amber-300' : ''}
              ${i === 1 ? 'bg-slate-300' : ''}
              ${i === 2 ? 'bg-orange-200' : ''}
              ${i > 2 && i % 2 === 0 ? 'bg-purple-75' : ''}`}
              >
                <p className="text-white sm:text-xl">
                  <Link
                    className={`${i <= 2 ? 'text-purple-200' : ''} font-bold`}
                    to={`/profile/${user.username}`}
                  >
                    {user.username}
                  </Link>
                  {' '}
                  with
                  {' '}
                  <Link
                    className={`${i <= 2 ? 'text-purple-200' : ''} font-bold`}
                    to={`/post/${user.postId}?public=true`}
                  >
                    {user.title}
                  </Link>
                </p>
                <p className="text-white font-bold sm:text-xl">
                  {user.points}
                  {' '}
                  points
                </p>
              </div>
            ))
          )}
          {
            leaderboard.length === 0 && !loading && (
              <p className="text-white text-center text-xl font-semibold h-full flex
              flex-row items-center justify-center px-4"
              >
                No one has posted yet! Post and see yourself up here
              </p>
            )
          }
        </div>
      </div>
    </div>
  );
}
