import { useState, useEffect } from 'react';
import api from '../Api/requests';
import Prompt from '../Types/Prompt';

export default function usePromptFetch() {
  const [prompt, setPrompt] = useState<Prompt | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.makePromptRequest();
        setPrompt(res.data.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  return { prompt, loading };
}
