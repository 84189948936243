import { useNavigate } from 'react-router-dom';
import getFormattedDate from '../../Utils/getFormattedDate';

interface IProps {
  title: string;
  points: number;
  date: Date;
  id: String;
  isPrivate: boolean;
}

export default function ProfilePost({
  title, points, date, id, isPrivate,
}: IProps) {
  const navigate = useNavigate();
  function handleClick() {
    if (isPrivate) {
      navigate(`/post/${id}?public=false`);
    } else {
      navigate(`/post/${id}?public=true`);
    }
  }
  return (
    <button className="bg-purple-200 p-5 rounded-lg w-full hover:-translate-y-1 transition ease-linear" onClick={handleClick} type="button">
      <h1 className="text-white text-2xl font-semibold">{title}</h1>
      <div className="flex flex-row gap-2 mt-4 mt-3">
        <h1 className="text-amber-300 sm:text-2.5xl text-xl font-bold">
          {points}
          {' '}
          pts.
        </h1>
        <h1 className="text-white sm:text-2.5xl text-xl font-semibold">
          -
          {' '}
          {getFormattedDate(date)}
        </h1>
      </div>
    </button>
  );
}
