import { useParams, Link, useLocation } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { useMemo } from 'react';
import usePostFetch from '../../Hooks/usePostFetch';
import Headerbar from '../../General/Headerbar';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function IndividualPostPage() {
  const { id } = useParams();
  const queryParams = useQuery();
  const isPublic = JSON.parse(queryParams.get('public') as string);
  // eslint-disable-next-line no-unused-vars
  const { post, isLoading, error } = usePostFetch(id, (isPublic ? 'yes' : 'no'));
  return (
    <div className="bg-purple-200 w-screen sm:h-screen min-h-screen pt-1 flex flex-row">
      <Headerbar />
      <div className="sm:pt-28 pt-40 h-full sm:w-2/3 w-4/5 sm:pb-0 pb-10 m-auto flex flex-col items-center justify-center">
        {
        isLoading
          ? <TailSpin arialLabel="loading-indicator" color="white" />
          : (
            <div className="bg-purple-100 rounded-lg p-4 w-full h-3/4 flex flex-col">
              <h1 className="text-white sm:text-xl font-semibold">{post?.prompt}</h1>
              <div className="bg-white w-full text-xl focus:outline-none py-2 px-4 rounded mt-4 whitespace-normal">{post?.title}</div>
              <h1 className="text-white text-xl font-semibold py-2">
                By:
                {' '}
                <Link to={`/profile/${post?.author}`} className="underline">
                  {post?.author}
                </Link>
                {' - '}
                <span className="text-amber-300 text-xl font-bold">
                  {post?.points}
                  {' '}
                  pts.
                </span>
              </h1>
              <div className="bg-white w-full text-lg focus:outline-none p-8 rounded h-full mt-2 break-words overflow-y-auto">{post?.body}</div>
            </div>
          )
        }
      </div>
    </div>
  );
}
