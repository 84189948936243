import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import Headerbar from '../../General/Headerbar';
import useLocalStorageReadWrite from '../../Hooks/useLocalStorageReadWrite';
import usePromptFetch from '../../Hooks/usePromptFetch';

import api from '../../Api/requests';

export default function Write() {
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { prompt, loading: loadingPrompt } = usePromptFetch();
  const navigate = useNavigate();

  const { clearValue: clearBody } = useLocalStorageReadWrite(text, setText, 'currentText');
  const { clearValue: clearTitle } = useLocalStorageReadWrite(title, setTitle, 'currentTitle');
  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const newWordCount = e.target.value.split(' ').filter((ch: string) => ch !== '').length;
    const newCharacterCount = e.target.value.split('').length;
    if (newWordCount > 400) { // @todo make this into a constant
      return;
    }
    if (newCharacterCount > 2000) { // @todo make this into a constant
      return;
    }
    setCharacterCount(newCharacterCount);
    setText(e.target.value);
    setWordCount(newWordCount);
  }
  function handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newCharacterCount = e.target.value.split('').length;
    if (newCharacterCount > 50) { // @todo make this into a constant
      return;
    }
    setTitle(e.target.value);
  }

  function handlePrivateOptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsPrivate(e.target.checked);
  }
  async function handleSubmit() {
    setIsLoading(true);
    try {
      if (isPrivate) {
        const res = await api.makePostSubmitRequest(title, text, isPrivate);
        clearBody();
        clearTitle();
        navigate(`/post/${res.data.data}?public=false`);
      } else {
        const res = await api.makeCheckPublicPostRequest();
        if (res.data.message === 'You already created a public post') {
          setError('You have already submitted a public post today');
          setIsLoading(false);
        } else {
          navigate('/rate');
        }
      }
    } catch (err: any) {
      // get code
      if (err.response.status === 401) {
        setError('You must be logged in to write. Please relogin');
      } else if (err.response.status === 409) {
        setError('You have already submitted a public post today');
      } else {
        setError('Please make sure the title and body are not empty');
      }
      setIsLoading(false);
    }
  }
  return (
    <div>
      <Headerbar />
      <div className="bg-purple-200 py-10 pt-36 sm:h-screen min-h-screen">
        <div className="relative sm:px-10 px-2 flex flex-col items-center gap-10 sm:w-2/3 w-4/5 mx-auto h-full">
          <div className="sm:w-4/5 w-full flex-shrink-0 h-full flex flex-col gap-8">
            {
              error !== ''
              && (
                <p className="text-red-600 text-lg font-semibold">{error}</p>
              )
            }
            <div className="flex sm:flex-row flex-col items-center w-full sm:gap-8 gap-2">
              <div className="flex flex-row gap-8">
                <div>
                  <h1 className="sm:text-xl sm:w-full w-20 sm:text-left text-center font-bold text-white sm:pb-1 pb-2">Word count:</h1>
                  <div className="sm:w-full w-20 bg-purple-100 text-white font-bold sm:text-xl rounded-lg sm:p-4 p-2">
                    {wordCount}
                  </div>
                </div>
                <div>
                  <h1 className="sm:text-xl sm:w-full w-20 sm:text-left text-center font-bold text-white sm:pb-1 pb-2">Character count:</h1>
                  <div className="sm:w-full w-20 bg-purple-100 text-white font-bold sm:text-xl rounded-lg sm:p-4 p-2">
                    {characterCount}
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-8">
                <div>
                  <h1 className="sm:text-xl sm:w-full w-20 sm:text-left text-center font-bold text-white sm:pb-1 pb-2">Maximum words:</h1>
                  <div className="sm:w-full w-20 bg-purple-100 text-white font-bold sm:text-xl rounded-lg sm:p-4 p-2">
                    400
                  </div>
                </div>
                <div>
                  <h1 className="sm:text-xl sm:w-full w-20 sm:text-left text-center font-bold text-white sm:pb-1 pb-2">Character cap:</h1>
                  <div className="sm:w-full w-20 bg-purple-100 text-white font-bold sm:text-xl rounded-lg sm:p-4 p-2">
                    2000
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-purple-100 rounded-lg p-4 w-full h-full flex flex-col">
              {
                  loadingPrompt
                    ? <div className="m-auto"><TailSpin color="#fff" width={100} height={100} /></div>
                    : (
                      <>
                        <h1 className="text-white font-semibold shrink-0 shrink-0">
                          {prompt?.prompt}
                        </h1>
                        <input value={title} onChange={handleTitleChange} placeholder="Title your story" className="bg-white w-full text-xl font-semibold focus:outline-none py-2 px-4 rounded mt-4" />
                        <textarea value={text} onChange={handleChange} className="bg-white w-full text-lg focus:outline-none p-8 rounded mt-4 sm:h-full h-150" />
                      </>
                    )
              }
            </div>
            <div className="flex flex-row gap-4">
              {
              Cookies.get('username')
                ? (
                  <>
                    <div className="bg-purple-100 text-white font-bold rounded-lg p-4 flex flex-row gap-4 items-center">
                      <p className="text-white text-lg">Private</p>
                      <input checked={isPrivate} onChange={handlePrivateOptionChange} type="checkbox" className="h-4 w-4" />
                    </div>
                    <button className="w-44 bg-purple-100 text-white font-bold text-xl rounded-lg p-4 flex flex-row justify-center" type="button" onClick={handleSubmit}>
                      {
                        isLoading
                          ? <TailSpin color="#fff" width={30} height={30} />
                          : 'Publish'
                      }
                    </button>
                  </>
                )
                : (
                  <Link to="/profile" className="text-center w-44 bg-purple-100 text-white font-bold text-xl rounded-lg p-4">
                    Sign In
                  </Link>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
