/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { TailSpin } from 'react-loader-spinner';
import Headerbar from '../../General/Headerbar';
import usePaginatedPostsFetch from '../../Hooks/usePaginatedPostsFetch';
import ExplorerPost from './ExplorerPost';

export default function Explorer() {
  const [page, setPage] = useState(1);
  const { posts, totalPages, isLoading } = usePaginatedPostsFetch(page);
  function handlePageClick(event: any) {
    setPage(event.selected + 1);
  }
  return (
    <div className="bg-purple-200 w-screen h-screen pt-28">
      <Headerbar />
      <div className="h-4/5 w-full m-auto flex flex-col items-center overflow-y-scroll">
        {
          isLoading ? (
            <div className="mt-20">
              <TailSpin color="#fff" />
            </div>
          ) : (
            posts.map((post) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <ExplorerPost key={post._id} {...post} />
            )))
        }
        {
          !isLoading && posts.length === 0 && (
            <p className="text-white text-lg font-semibold mt-10 px-6 text-center">
              No posts yet today. Post and see yourself up here!
            </p>
          )
        }
      </div>
      <div className="fixed mx-auto bottom-0 z-40 h-20 w-screen flex flex-row justify-center">
        {
          totalPages !== 0
              && (
              <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={totalPages}
                forcePage={page - 1}
                previousLabel="<"
                nextLabel=">"
                containerClassName="flex flex-row text-white"
                pageClassName="h-10 w-10 rounded mx-1"
                activeLinkClassName="border-2 border-purple-200 bg-purple-50 text-purple-100"
                pageLinkClassName="text-lg bg-purple-100 h-10 w-10 rounded flex flex-row items-center justify-center"
                previousClassName="text-lg bg-purple-100 h-10 w-5 rounded flex flex-row items-center justify-center mr-1"
                nextClassName="text-lg bg-purple-100 h-10 w-5 rounded flex flex-row items-center justify-center ml-1"
                previousLinkClassName="text-lg bg-purple-100 h-10 w-5 rounded flex flex-row items-center justify-center"
                nextLinkClassName="text-lg bg-purple-100 h-10 w-5 rounded flex flex-row items-center justify-center"
              />
              )
        }

      </div>
    </div>
  );
}
