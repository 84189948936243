/* eslint-disable no-nested-ternary */
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import SmallLogo from '../Icons/SmallLogo';

export default function Headerbar() {
  const [isOpen, setOpen] = useState(false);
  function signOut() {
    Cookies.remove('username');
    Cookies.remove('accessToken');
    // send to home page
    window.location.href = '/';
  }

  function handleToggle() {
    const dropdown = document.getElementById('dropdown');
    if (dropdown) {
      if (dropdown.classList.contains('open')) {
        dropdown.classList.remove('open');
      } else {
        dropdown.classList.add('open');
      }
    }
  }

  return (
    <div className="fixed bg-purple-100 w-full h-28 flex flex-row items-center top-0 shadow z-50">
      <Link to="/">
        <div className="sm:mb-4 sm:ml-10 ml-2 z-100">
          <SmallLogo />
        </div>
      </Link>
      <div className="block sm:hidden w-full">
        <div className="w-full flex flex-row justify-end z-100 pr-4">
          <Hamburger toggled={isOpen} toggle={setOpen} onToggle={handleToggle} color="white" />
        </div>
        <div id="dropdown" className="absolute left-0 top-0 -z-10 flex flex-col gap-4 dropdown h-screen w-screen">
          <Link to="/leaderboard">
            <div className="w-4/5 mt-40 mx-auto transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 text-center">
              <p className="opacity-70 font-sans font-extrabold text-purple-200">
                Leaderboard
              </p>
            </div>
          </Link>
          <Link to="/explorer">
            <div className="mt-1 mx-auto w-4/5 transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 text-center">
              <p className="opacity-70 font-sans font-extrabold text-purple-200">
                Explore
              </p>
            </div>
          </Link>
          <Link to="/write">
            <div className="mt-1 mx-auto w-4/5 transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 text-center">
              <p className="opacity-70 font-sans font-extrabold text-purple-200">
                Write
              </p>
            </div>
          </Link>
          {Cookies.get('username') && (
          <button
            onClick={signOut}
            type="button"
            className="mt-1 mx-auto w-4/5 transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 text-center"
          >
            <p className="opacity-70 font-sans font-extrabold text-purple-200">
              Sign Out
            </p>
          </button>
          )}
          {Cookies.get('username') ? (
            <Link
              to={`/profile/${Cookies.get('username')}`}
            >
              <div className="mt-1 mx-auto w-4/5 mb-3 transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 text-center">
                <p className="opacity-70 font-sans font-extrabold text-purple-200">
                  Profile
                </p>
              </div>
            </Link>
          ) : (
            <Link to="/signin">
              <div className="mt-1 mx-auto w-4/5 mb-3 transition-all ease-in-out bg-purple-50 rounded-xl hover:mb-2 py-2 text-center">
                <p className="opacity-70 font-sans font-extrabold text-purple-200">
                  Sign In
                </p>
              </div>
            </Link>
          )}
        </div>
      </div>
      <div className="sm:flex hidden ml-auto flex-row justify-end lg:gap-8 md:gap-2 mr-20 h-full items-center">
        <Link to="/leaderboard">
          <div className="transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 lg:w-40 md:w-32 text-center">
            <p className="opacity-70 font-sans font-extrabold text-purple-200 lg:text-xl md:text-sm">
              Leaderboard
            </p>
          </div>
        </Link>
        <Link to="/explorer">
          <div className="transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 lg:w-40 md:w-32 text-center">
            <p className="opacity-70 font-sans font-extrabold text-purple-200 lg:text-xl md:text-sm">
              Explore
            </p>
          </div>
        </Link>
        <Link to="/write">
          <div className="transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 lg:w-40 md:w-32 text-center">
            <p className="opacity-70 font-sans font-extrabold text-purple-200 lg:text-xl md:text-sm">
              Write
            </p>
          </div>
        </Link>
        {Cookies.get('username') && (
          <button
            onClick={signOut}
            type="button"
            className="transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 lg:w-40 md:w-32 text-center"
          >
            <p className="opacity-70 font-sans font-extrabold text-purple-200 lg:text-xl md:text-sm">
              Sign Out
            </p>
          </button>
        )}
        {Cookies.get('username') ? (
          <Link
            to={`/profile/${Cookies.get('username')}`}
            className="shrink-0"
          >
            {Cookies.get('profileIcon') ? (
              <img
                src={Cookies.get('profileIcon')}
                alt="profile"
                className="transition-all ease-in-out hover:scale-105 lg:h-16 lg:w-16 md:h-12 md:w-12 border-4 border-purple-200 rounded-full shrink-0"
              />
            ) : (
              <div className="transition-all ease-in-out bg-purple-50 rounded-xl hover:scale-105 py-2 lg:w-40 md:w-32 text-center">
                <p className="opacity-70 font-sans font-extrabold text-purple-200 lg:text-xl md:text-sm">
                  Profile
                </p>
              </div>
            )}
          </Link>
        ) : (
          <Link to="/signin">
            <div className="transition-all ease-in-out bg-purple-50 rounded-xl hover:mb-2 py-2 lg:w-40 md:w-32 text-center">
              <p className="opacity-70 font-sans font-extrabold text-purple-200 lg:text-xl md:text-sm">
                Sign In
              </p>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}
