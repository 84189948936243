export default function Wave3({ color }: {color?: string}) {
  return (
    <svg
      viewBox="0 0 1770 184"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1770 18.4L1708.67 27.6C1647.33 36.8 1524.67 55.2 1402 70.5525C1279.33 85.675 1156.67 98.325 1034 88.9525C911.333 79.925 788.667 48.875 666 39.8475C543.333 30.475 420.667 43.125 298 39.8475C175.333 36.8 52.6666 18.4 -8.66663 9.2L-70 7.62939e-06L-8.66663 7.62939e-06C52.6666 7.62939e-06 175.333 7.62939e-06 298 7.62939e-06C420.667 7.62939e-06 543.333 7.62939e-06 666 7.62939e-06C788.667 7.62939e-06 911.333 7.62939e-06 1034 7.62939e-06C1156.67 7.62939e-06 1279.33 7.62939e-06 1402 7.62939e-06C1524.67 7.62939e-06 1647.33 7.62939e-06 1708.67 7.62939e-06L1770 7.62939e-06V18.4Z"
        fill={color}
      />
    </svg>
  );
}

Wave3.defaultProps = {
  color: '#5847AD',
};
