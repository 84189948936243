import { useState, useEffect } from 'react';
import Post from '../Types/Post';
import api from '../Api/requests';

export default function usePostFetch(id: string | undefined, isPublic: string | undefined) {
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>();
  useEffect(() => {
    async function fetchData() {
      if (!id) return;
      try {
        if (isPublic === 'yes') {
          const res = await api.makePublicUserPostRequest(id);
          setPost(res.data.data);
          setIsLoading(false);
        } else if (isPublic === 'no') {
          const res = await api.makePrivateUserPostRequest(id);
          setPost(res.data.data);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setError(err);
      }
    }
    fetchData();
  }, [id, isPublic]);
  return { post, isLoading, error };
}
