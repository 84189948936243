import {
  BrowserRouter, Routes,
  Route,
} from 'react-router-dom';
import Homepage from './Pages/Homepage/Homepage';
import Profile from './Pages/Profile/Profile';
import Settings from './Pages/Settings/Settings';
import Write from './Pages/Write/Write';
import Signin from './Pages/Profile/Signin';
import IndividualPostPage from './Pages/IndividualPostPage/IndividualPostPage';
import Explorer from './Pages/Explorer/Explorer';
import Leaderboard from './Pages/Leaderboard/Leaderboard';
import Rate from './Pages/Rate/Rate';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/profile/:username" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/write" element={<Write />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/post/:id" element={<IndividualPostPage />} />
        <Route path="/explorer" element={<Explorer />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/rate" element={<Rate />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
