export default function Footer() {
  return (
    <div className="relative font-extrabold text-amber-100 text-lg bg-purple-200 py-5 flex flex-row justify-center items-end text-center">
      © TaleTest 2022. All Rights Reserved.
      <br />
      <br />
      TOS  •  Privacy  •  Contact
    </div>
  );
}
